import React from 'react';
import FrameworkOverviewSummary from './framework-overview-summary';

const Frameworks = () => {

    return(
        <div className="container">
            <FrameworkOverviewSummary />           
        </div>
    );

}

export default Frameworks;